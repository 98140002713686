import React, { useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import axios from "axios";
import "./login.css";
import swal from "sweetalert";
import {
  authenticateOwnerSignup,
  getOwnerId,
  loginOwnerId,
  authProcess,
  securitysuccessfullMsg,
  changeButton,
  ownerId,
  getownerIdbeforeLogin,
  handleEmailVerifyFromLogin,
} from "../../redux-toolkit/reducer/accountCreationApiReducer";
import { useDispatch, useSelector } from "react-redux";
import {
  currentCareSiteDataHandler,
  currentCareSiteIdHandler,
  currentCareSiteTokenHandler,
} from "../../redux-toolkit/reducer/switchSites";
import LoadingBtn from "../../components/Toggle_Button/LoadingBtn";

function Login() {
  //security questions
  let history = useHistory();
  const dispatch = useDispatch();
  const { loginVerifyEmail, verifySuccessLogin } = useSelector(
    (state) => state.accountCreationApiReducer
  );

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [location, setLocation] = useState("");
  const [trackIdInLogin, setTrackIdInLogin] = useState("");
  const [loading, setLoading] = useState(false);

  const authProcessSignup = "signup";

  // const [coord, setCoord] = useState("");
  // useEffect(() => {
  //   navigator.geolocation?.getCurrentPosition( (position) => {
  //     setCoord(position.coords);
  //   });
  // }, []);

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const response = await fetch("https://ipapi.co/json/");
        const data = await response.json();
        setLocation(data);
      } catch (error) {
        console.error("Error fetching location:", error);
      }
    };
    fetchLocation();
  }, []);
  /// Email verification through api
  useEffect(() => {
    let intervalID = setInterval(() => {
      if (loginOwnerId && loginVerifyEmail == 1) {
        dispatch(authenticateOwnerSignup({ loginOwnerId, authProcess }));
      }
    }, 3000);
    if (loginVerifyEmail == 2) {
      swal("Success", securitysuccessfullMsg, "success").then((value) => {
        history.push("/admin/dashboard");
      });
      dispatch(
        getOwnerId({
          loginOwnerId: "",
          loginVerifyEmail: 0,
          authProcess: "login",
        })
      );
      return () => clearInterval(intervalID);
    }
    return () => clearInterval(intervalID);
  }, [loginVerifyEmail]);

  useEffect(() => {
    let intervalID = setInterval(() => {
      if (ownerId && verifySuccessLogin == 1) {
        dispatch(
          authenticateOwnerSignup({
            loginOwnerId: ownerId,
            authProcess: authProcessSignup,
          })
        );
      }
    }, 3000);
    if (verifySuccessLogin == 2) {
      dispatch(
        changeButton({
          currentNo: 2,
          buttonText: "Next",
          stepNo: 2 + 1,
          nextNo: 2 + 1,
        })
      );
      setTimeout(() => {
        history.push("/multistepsignupform", {
          trackIdParam: trackIdInLogin,
        });
        return () => clearInterval(intervalID);
      }, 1000);
    }
    return () => clearInterval(intervalID);
  }, [verifySuccessLogin]);

  function signin() {
    setLoading(true);
    let userAgent = navigator?.userAgent;

    // Check for iOS devices
    if (location) {
      if (/(iPhone|iPad|iPod)/i.test(userAgent)) {
        location.device_type = "iOS Mobile device";
        return "iOS";
      } else if (/(Android)/i.test(userAgent)) {
        location.device_type = "Android Mobile device";
      } else if (/(Windows NT)/i.test(userAgent)) {
        location.device_type = "Windows PC";
      } else if (/(Macintosh)/i.test(userAgent)) {
        location.device_type = "Mac OS device";
      } else if (/(Linux)/i.test(userAgent)) {
        location.device_type = "Linux OS device";
      } else {
        location.device_type = "Unknown";
      }
    }

    let data = JSON.stringify({
      email: email,
      password: password,
      deviceAuth: {
        deviceInfo: location,
        authenticateStatus: true,
      },
    });

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/login`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config).then(function (response) {
      setLoading(false);
      if (response.data.status === true) {
        // ownerId = response.data.data;
        swal("Success", response.data.message, "success").then((value) => {
          if (
            response.data?.message?.includes(
              "An authentication email has been sent to your email, Please authenticate yourself"
            )
          ) {
            dispatch(
              getOwnerId({
                loginOwnerId: response.data.data,
                loginVerifyEmail: 1,
                authProcess: "login",
              })
            );
          } else if (
            response?.data?.message.includes(
              "New device authentication email sent to your email, Please authenticate device"
            )
          ) {
            dispatch(
              getOwnerId({
                loginOwnerId: response.data.data,
                loginVerifyEmail: 1,
                authProcess: "device",
              })
            );
          } else if (
            response.data.message.includes("Please authenticate yourself")
          ) {
            return;
          } else if (
            response.data.message.includes("Security question enabled")
          ) {
            history.push(`/admin/security/${response?.data?.userId}`);
          }

          if (response.data.message.includes("logged in successfully")) {
            // console.log(response.data.adminData, "loggedInData");
            localStorage.removeItem("care_admin_token");
            localStorage.setItem("care_admin_token", response?.data?.token);
            // localStorage.setItem(
            //   "carehomeId",
            //   response.data.adminData.subscriptionPlanData[0].caresiteId
            // );

            // localStorage.setItem(
            //   "currentCareSiteId",
            //   response.data.adminData.defaultCareSiteId
            // );
            dispatch(
              currentCareSiteIdHandler({
                currentCareSiteId: response.data?.adminData?.defaultCareSiteId,
                status: "login",
                loginData: response.data?.adminData,
              })
            );
            dispatch(
              currentCareSiteTokenHandler({
                care_admin_token: response.data?.token,
              })
            );
            dispatch(
              currentCareSiteDataHandler({
                userData: response?.data?.adminData,
              })
            );

            // localStorage.setItem(
            //   "userData",
            //   JSON.stringify(response.data.adminData)
            // );
            history.push("/admin/dashboard");
          }
        });
      } else {
        if (response.data?.data?.isEmailVerified === false) {
          swal(
            "Information",
            "Please verify your email to complete signup process",
            "info"
          ).then((value) => {
            // dispatch(getownerIdbeforeLogin(response?.data?.data?._id));
            dispatch(
              handleEmailVerifyFromLogin({
                ownerId: response?.data?.data?._id,
                verifySuccessLogin: 1,
                signupVerification: true,
              })
            );
          });
        } else {
          swal("Information", response.data.message, "info").then((value) => {
            if (response?.data?.stepCompleted === 1) {
              localStorage.setItem(
                "userRegisterData",
                JSON.stringify(response.data?.data)
              );
              localStorage.setItem("careAdminToken", response.data?.token);

              dispatch(getownerIdbeforeLogin(response?.data?.data?._id));
              dispatch(
                changeButton({
                  currentNo: 2,
                  buttonText: "Next",
                  stepNo: 2 + 1,
                  nextNo: 2 + 1,
                })
              );
              history.push("/multistepsignupform", {
                trackIdParam: response?.data?.trackSignUp?._id,
              });
            } else if (
              response?.data?.stepCompleted === 2 ||
              response?.data?.stepCompleted === 3
            ) {
              localStorage.setItem(
                "userRegisterData",
                JSON.stringify(response.data?.data)
              );
              localStorage.setItem("careAdminToken", response.data?.token);
              dispatch(
                getownerIdbeforeLogin({ ownerId: response?.data?.data?._id })
              );
              history.push(`/admin/purchagePlan`, {
                ownerId: response?.data?.data?._id,
                trackIdParam: response?.data?.trackSignUp?._id,
                updatePlan: false,
                newCareSite: false,
              });
            }
          });
        }
      }
    })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleKeypress = (e) => {
    setLoading(false);
    //it triggers by pressing the enter key
    if (e.keyCode === 13) {
      signin();
    }
  };

  return (
    <section className="login_section">
      <div className="login_header">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12 logo_box">
              <NavLink className="navbar-brand" to="/">
                <b className="logo-icon">
                  <img alt="" src={`/images/Newfooter_Logo.svg`} />
                </b>
              </NavLink>
            </div>
            <div className="col-md-9">
              <div className="login_title">
                <h1>Care Management Simplified!</h1>
                <p>
                  Welcome to your new experience of care management. Our
                  powerful, easy to use <br /> and intuitive care platform, enables you
                  to easily manage all you care tasks.
                </p>
              </div>
            </div>
            <div className="col-md-3 img_box">
              <img alt="" src={`/images/NewhomeRightside.svg`} />
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4 form_box">
        <form>
          <h4>Login</h4>
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyUp={(e) => {
              handleKeypress(e);
            }}
            type="email"
            className="form-control"
            placeholder="Email"
          />
          <input
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyUp={(e) => {
              handleKeypress(e);
            }}
            type="password"
            className="form-control"
            placeholder="Password"
          />
          <NavLink to="/admin/forgotpassword" className="forgot">
            Forgot Password?
          </NavLink>
          <NavLink to="/login">
            <button
              disabled={loading}
              type="button"
              onClick={signin}
              className="form-control btn"
            >{loading ? <LoadingBtn /> : 'Login'}</button>
          </NavLink>
        </form>
        <div className="option_box">
          {/* <div className="or"><span>OR</span></div>
                <ul>
                    <li><Link to="/" ><img alt="" src={`/images/facebook.png`} /></Link></li>
                    <li><NavLink to=""><img alt="" src={`/images/google.png`} /></NavLink></li>
                </ul> */}
          <p>
            Don’t have an account?{" "}
            <NavLink to="/multistepsignupform">Sign up</NavLink>
          </p>
        </div>
      </div>
    </section>
  );
}

export default Login;
