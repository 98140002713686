import React, { useState, useEffect } from "react";
import "../ShiftTask/ShiftTask.css";
import "react-calendar/dist/Calendar.css";
import axios from "axios";
import { currentCareSiteId } from "../../redux-toolkit/reducer/switchSites";
import { Modal } from "react-bootstrap";
import { BsSearch } from "react-icons/bs";
import Select from "react-select";
import { ShiftTaskStatus, taskCtgImages, taskStatusColors } from "../../constants/constants";
import "./CreateShiftTask.css";
import DatePicker from "react-datepicker";
import { convertTimeToUserTimeZone, convertToTimeZone, formatDate, getFullName, getMondayDate, getMonthAgoDate } from "../../utils/CommonFunctions";
import { httpAdmin } from "../../Apis/commonApis";
import { Switch, Route, NavLink, useRouteMatch, useLocation, useHistory } from "react-router-dom";
import RotaRoutes from "./RotaRouter";
import { ShowSwal } from "../../utils/alertSwal";
import moment from 'moment'
import { useSelector } from "react-redux";

let monthAgoDate = getMonthAgoDate();
let today = new Date().toISOString().slice(0, 10);

function Shift() {

  const history = useHistory();
  let { path, url } = useRouteMatch();
  const location = useLocation();

  const { currentCareSiteData } = useSelector((state) => state.caresiteDataReducer);

  const [rowData, setRowData] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [reloadData, setReloadData] = useState(0);
  const [filterType, setFilterType] = useState("week");
  const [dateRange, setDateRange] = useState([monthAgoDate, new Date()]);
  const [startDate, endDate] = dateRange;

  useEffect(() => {
    const intervalId = setInterval(() => {
      setReloadData((prev) => prev + 1);
    }, 10000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (location.state) {
      let fType = location.state.filterType;
      setFilterType(fType);
      if (fType === "custom") {
        setDateRange(location.state.dateRange);
      }
      handleNavigate('task-status');
    } else {
      let pathname = window?.location?.pathname;
      if (pathname?.includes('task-status') || pathname?.includes('schedule')) {
      } else {
        handleNavigate('schedule');
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    if (currentCareSiteData) {
      if (filterType === "custom" && endDate) {
        getShiftsandtaskData(formatDate(startDate), formatDate(endDate));
      } else if (filterType === "week") {
        let mondayDate = getMondayDate();
        getShiftsandtaskData(formatDate(mondayDate), formatDate(today));
      }
    }
  }, [filterType, dateRange, reloadData, currentCareSiteData]);

  const getShiftsandtaskData = (start, end) => {
    let filterValue = "all";
    if (location.state) {
      filterValue = location.state.filter;
    }
    axios({
      url: `${process.env.REACT_APP_BASEURL}/getShiftsandtaskNew?careHomeId=${currentCareSiteId}&startDate=${start}&endDate=${end}&filter=${filterValue}`,
      method: "GET",
      headers: { Authorization: localStorage.getItem("care_admin_token") },
    }).then((res) => {
      let siftData = res.data.shiftData;
      if (currentCareSiteData?.carefacility === "facalities") {
        handleGetShiftFacilityData(siftData);
      } else if (currentCareSiteData?.carefacility === "agency") {
        handleGetShiftAgencyData(siftData);
      }
    }).catch((error) => console.log(`Error: ${error}`));
  };

  function handleGetShiftAgencyData(shiftData) {
    let tasksArr = [];

    let checkInOutArr = [];
    shiftData?.forEach((client) => {
      let clientId = client?._id;
      let CareTeamData = client?.shiftDetails;
      let wellOverdueCompDate = '';
      let wellPendingCompDate = '';
      let taskCompareDate = '';
      CareTeamData?.map((shiftItem, index) => {
        let shiftTaskItem = shiftItem?.shiftData;
        let shiftTimeZone = shiftTaskItem?.timeZone;
        let shiftsCareTeamId = shiftTaskItem?.careTeamId[0]?._id ?? shiftTaskItem?.careTeamId;
        let shiftStartTime = `${convertTimeToUserTimeZone(shiftTaskItem?.startingTime, shiftTimeZone) ?? '--'}`;
        let shiftEndTime = `${convertTimeToUserTimeZone(shiftTaskItem?.endingTime, shiftTimeZone) ?? '--'}`;
        /* @field filterAssignedShifts
         * is used for no showing unassigned and open shifts
         * if the shiftTaskItem.recurringShiftType param is coming "unassinged" or "open"*/
        let filterAssignedShifts = false;
        if (shiftTaskItem?.recurringShiftType == "unassigned")
          filterAssignedShifts = false;
        else if (shiftTaskItem?.recurringShiftType == "open")
          filterAssignedShifts = false;
        else filterAssignedShifts = true;

        // Wellbeing Task In Every Shift
        if (filterAssignedShifts && shiftTaskItem) {
          let wellbeingTaskStatus;
          let wellbeingTaskbgColor;
          let taskStatusObj = taskStatusColors.find(elem => elem.status === shiftTaskItem?.wellbeingAssessment?.status);
          wellbeingTaskbgColor = taskStatusObj?.bgColor ?? 'green';
          wellbeingTaskStatus = taskStatusObj?.name ?? 'Completed';
          const combinedDateTime = moment(`${shiftTaskItem?.Date} ${shiftTaskItem?.startingTime}:00`, 'YYYY-MM-DD HH:mm:ss').tz(shiftTaskItem?.timeZone || 'Asia/Calcutta').format();
          const wellbeingObj = {
            _id: shiftTaskItem._id,
            id: shiftTaskItem._id + index + "wellbeingAssessment",
            clientName: shiftTaskItem.forClient,
            clientId: clientId,
            RiskProfile: "",
            TaskType: "/images/s9.svg",
            Task: "Wellbeing",
            notes: "notes",
            shiftPeriod: `${shiftTaskItem?.startingTime} - ${shiftTaskItem?.endingTime}`,
            taskTime: shiftStartTime,
            timeDone: shiftTaskItem?.wellbeingAssessment?.timestamp ? convertToTimeZone(shiftTaskItem?.wellbeingAssessment?.timestamp) : "--:--",
            taskStatus: wellbeingTaskStatus,
            bgColor: wellbeingTaskbgColor,
            shiftId: shiftTaskItem._id,
            date: shiftTaskItem?.Date,
            dateToShort: shiftTaskItem?.wellbeingAssessment?.timestamp ?? combinedDateTime
          }
          if (shiftTaskItem?.wellbeingAssessment?.status === 'true' || shiftTaskItem?.wellbeingAssessment?.status === "omitted") {
            if (shiftTaskItem.wellbeingAssessmentDoneBy === shiftsCareTeamId && shiftTaskItem._id === shiftTaskItem?.wellbeingAssessment?.shiftId) {
              tasksArr.push({ ...wellbeingObj, careteamName: shiftTaskItem.careTeamMember });
              wellOverdueCompDate = shiftTaskItem?.Date;
            }
          } else if (shiftTaskItem?.wellbeingAssessment?.status === 'overdue') {
            if (wellOverdueCompDate !== shiftTaskItem?.Date) {
              wellbeingObj.taskTime = '--:--';
              wellbeingObj.shiftPeriod = '--:--';
              tasksArr.push({ ...wellbeingObj, careteamName: '' });
              wellOverdueCompDate = shiftTaskItem?.Date;
            }
          } else {
            if (wellPendingCompDate !== shiftTaskItem?.Date) {
              if (shiftTaskItem?.wellbeingAssessment?.shiftTiming && shiftTaskItem?.wellbeingAssessment?.status === 'In-Process') {
                wellbeingObj.shiftPeriod = shiftTaskItem?.wellbeingAssessment?.shiftTiming;
              } else {
                wellbeingObj.shiftPeriod = '--:--';
              }
              wellbeingObj.taskTime = '--:--';
              tasksArr.push({ ...wellbeingObj, careteamName: '' });
              wellPendingCompDate = shiftTaskItem?.Date;
            }
          }

          // Clock IN Task Passing to Task Array
          if (shiftTaskItem?.checkinDateTime) {
            let checkinbgColor = "green";
            let checkinStatus = "Done";
            checkInOutArr.push({
              _id: shiftTaskItem._id,
              id: shiftTaskItem._id + index + "checkinDateTime",
              clientName: shiftTaskItem.forClient,
              clientId,
              RiskProfile: "",
              TaskType: "/images/s11.svg",
              Task: "Clock-In",
              careteamName: shiftTaskItem.careTeamMember,
              notes: "notes",
              shiftPeriod: `${shiftTaskItem?.startingTime} - ${shiftTaskItem?.endingTime}`,
              taskTime: shiftStartTime,
              timeDone: convertToTimeZone(shiftTaskItem.checkinDateTime),
              taskStatus: checkinStatus,
              bgColor: checkinbgColor,
              shiftId: shiftTaskItem._id,
              date: shiftTaskItem?.Date,
              dateToShort: combinedDateTime
              // dateToShort: convertToTimeZoneFullTime(shiftTaskItem.checkinDateTime)
            });
          }
          //  Clock Out Task Passing to Task Array 
          if (shiftTaskItem?.checkoutDateTime) {
            let checkoutbgColor = "green";
            let checkoutPlace = shiftTaskItem?.checkoutPlace ? `(${shiftTaskItem?.checkoutPlace})` : '';
            let checkoutStatus = shiftTaskItem.checkoutBy === "careTeamMember" ? "Clock-Out(Manual)" : `Clock-Out(Auto)${checkoutPlace}`;
            checkInOutArr.push({
              _id: shiftTaskItem._id,
              id: shiftTaskItem._id + index + "checkoutDateTime",
              clientName: shiftTaskItem.forClient,
              clientId,
              RiskProfile: "",
              TaskType: "/images/s11.svg",
              Task: checkoutStatus,
              checkoutStatus: checkoutStatus,
              careteamName: shiftTaskItem.careTeamMember,
              notes: "notes",
              shiftPeriod: `${shiftTaskItem?.startingTime} - ${shiftTaskItem?.endingTime}`,
              taskTime: shiftEndTime,
              timeDone: convertToTimeZone(shiftTaskItem.checkoutDateTime),
              taskStatus: "Completed",
              bgColor: checkoutbgColor,
              shiftId: shiftTaskItem._id,
              date: shiftTaskItem?.Date,
              dateToShort: combinedDateTime
              // dateToShort: convertToTimeZoneFullTime(shiftTaskItem.checkoutDateTime)
            });
          }

          shiftTaskItem.Task?.forEach((task, taskIndex) => {
            let bgColor = "";
            let taskStatus = "";
            let taskTime = "--:--";
            let timeOfTask = '';
            if (task.shiftType?.includes('within')) {
              taskTime = `${convertTimeToUserTimeZone(shiftTaskItem?.startingTime, shiftTimeZone) ?? '--'}`;
              timeOfTask = shiftTaskItem?.startingTime;
            } else {
              taskTime = `${convertTimeToUserTimeZone(task.startTime, shiftTimeZone) ?? '--'}`;
              timeOfTask = shiftTaskItem.startTime;
            }
            const combinedDateTime = moment(`${shiftTaskItem?.Date} ${timeOfTask}:00`, 'YYYY-MM-DD HH:mm:ss').tz(shiftTaskItem?.timeZone || 'Asia/Calcutta').format();
            // let combinedDateTime = combineDateTimeAndTimeZone(shiftTaskItem?.Date, timeOfTask, shiftTaskItem?.timeZone);
            const taskName = taskCtgImages.filter((i) => task.taskName?.includes(i.Ctg));
            let riskprofile = task?.riskProfileCategory ?? "";
            let taskStatusObj = taskStatusColors.find(elem => elem.status === task?.status) ?? taskStatusColors[0];
            bgColor = taskStatusObj?.bgColor;
            taskStatus = taskStatusObj?.name;
            /* *  if task is medication reminder then below status will be in task status
             *  'true' || 'false' || prn || self || assisted || assigned || overdue  || In-Process || omitted*/
            /* if task is except medication reminder then below status will be in task status
               'true' means completed || 'false' means pending || overdue ||  In-Process || omitted */
            if (task.medName) {
              let medImage = "";
              let Task = "";
              if (task?.prn && task?.prn === "Yes") {
                medImage = "/images/s6.svg";
                Task = "PRN Med";
              } else {
                medImage = "/images/s10.svg";
                Task = "Medication Reminder";
              }
              let medTaskObj = {
                _id: task._id,
                id: `${task._id}${index}_${taskIndex}medtask`,
                clientName: shiftTaskItem.forClient,
                clientId,
                RiskProfile: riskprofile,
                TaskType: medImage,
                Task: Task,
                notes: "notes",
                shiftPeriod: `${shiftTaskItem?.startingTime} - ${shiftTaskItem?.endingTime}`,
                taskTime: taskTime,
                timeDone: task?.timestamp ? convertToTimeZone(task?.timestamp) : "--:--",
                taskStatus: taskStatus,
                bgColor: bgColor,
                shiftId: shiftTaskItem._id,
                date: shiftTaskItem?.Date,
                dateToShort: combinedDateTime
              }
              if (task.status === 'true' || task.status === "omitted") {
                if (task.doneBy === shiftsCareTeamId && shiftTaskItem._id === task?.shiftId) {
                  tasksArr.push({ ...medTaskObj, careteamName: shiftTaskItem.careTeamMember });
                }
              } else {
                if (taskCompareDate !== shiftTaskItem?.Date) {
                  if (task.shiftTiming && task.status === 'In-Process') {
                    medTaskObj.shiftPeriod = task.shiftTiming;
                    medTaskObj.taskTime = task.shiftType?.includes('within') ? task.shiftTiming.split('-')[0] : `${convertTimeToUserTimeZone(task.startTime, shiftTimeZone) ?? '--:--'}`;
                  } else {
                    medTaskObj.shiftPeriod = '--:--';
                    medTaskObj.taskTime = task.shiftType?.includes('within') ? '--:--' : `${convertTimeToUserTimeZone(task.startTime, shiftTimeZone) ?? '--'}`;
                  }
                  tasksArr.push({ ...medTaskObj, careteamName: '-' });
                }
              }
            } else {
              let taskObj = {
                _id: task._id,
                id: `${task._id}${index}_${taskIndex}task`,
                clientName: shiftTaskItem.forClient || "",
                clientId,
                RiskProfile: riskprofile,
                TaskType: taskName[0]?.image || "",
                Task: task.taskType || "",
                notes: "notes",
                shiftPeriod: `${shiftTaskItem.startingTime} - ${shiftTaskItem.endingTime}`,
                taskTime: taskTime,
                timeDone: task?.timestamp ? convertToTimeZone(task?.timestamp) : "--:--",
                taskStatus: taskStatus || "",
                bgColor: bgColor,
                shiftId: shiftTaskItem._id,
                date: shiftTaskItem?.Date,
                dateToShort: combinedDateTime
              }
              if (task.status === 'true' || task.status === "omitted") {
                if (task.doneBy === shiftsCareTeamId && shiftTaskItem._id === task?.shiftId) {
                  tasksArr.push({ ...taskObj, careteamName: shiftTaskItem.careTeamMember || "", });
                }
              } else {
                if (taskCompareDate !== shiftTaskItem?.Date) {
                  if (task.shiftTiming && task.status === 'In-Process') {
                    taskObj.shiftPeriod = task.shiftTiming;
                    taskObj.taskTime = task.shiftType?.includes('within') ? task.shiftTiming.split('-')[0] : `${convertTimeToUserTimeZone(task.startTime, shiftTimeZone) ?? '--:--'}`;
                  } else {
                    taskObj.shiftPeriod = '--:--';
                    taskObj.taskTime = task.shiftType?.includes('within') ? '--:--' : `${convertTimeToUserTimeZone(task.startTime, shiftTimeZone) ?? '--'}`;
                  }
                  tasksArr.push({ ...taskObj, careteamName: '-' });
                }
              }
            }
          });
          taskCompareDate = shiftTaskItem?.Date;
        }
      });
    });

    // const uniqueDataArray = removeDuplicates(tasksArr);
    let combinedArr = [...checkInOutArr, ...tasksArr];

    // let sortedArr = sortByDateDescend(combinedArr, 'dateToShort');
    const sortedArr = combinedArr.sort(compareDates);
    setRowData(sortedArr);
  }

  function handleGetShiftFacilityData(shiftData) {
    let tasksArr = [];

    let checkInOutArr = [];
    shiftData?.forEach((client) => {
      let clientId = client?._id;
      let CareTeamData = client?.shiftDetails;
      let wellOverdueCompDate = '';
      let wellPendingCompDate = '';
      let taskCompareDate = '';
      CareTeamData?.map((shiftItem, index) => {
        const shiftTaskItem = shiftItem?.shiftData?.assignedCareTeam;
        const shiftTaskItemInitial = shiftItem?.shiftData;
        let shiftTimeZone = shiftTaskItem?.timeZone;
        let shiftsCareTeamId = shiftTaskItem?.careTeamId[0]?._id ?? shiftTaskItem?.careTeamId;
        let shiftsCareTeamName = shiftsCareTeamId ? getFullName(shiftTaskItem?.careTeamId[0]) : '';
        let shiftStartTime = `${convertTimeToUserTimeZone(shiftTaskItemInitial?.startingTime, shiftTimeZone) ?? '--'}`;
        let shiftEndTime = `${convertTimeToUserTimeZone(shiftTaskItemInitial.endingTime, shiftTimeZone) ?? '--'}`;
        /* @field filterAssignedShifts
         * is used for no showing unassigned and open shifts
         * if the shiftTaskItem.recurringShiftType param is coming "unassinged" or "open"*/
        let filterAssignedShifts = false;
        if (shiftTaskItem?.recurringShiftType == "unassigned")
          filterAssignedShifts = false;
        else if (shiftTaskItem?.recurringShiftType == "open")
          filterAssignedShifts = false;
        else filterAssignedShifts = true;

        // Wellbeing Task In Every Shift
        if (filterAssignedShifts) {
          let wellbeingTaskStatus;
          let wellbeingTaskbgColor;
          let taskStatusObj = taskStatusColors.find(elem => elem.status === shiftTaskItem.wellbeingAssessment?.status);
          wellbeingTaskbgColor = taskStatusObj?.bgColor ?? 'green';
          wellbeingTaskStatus = taskStatusObj?.name ?? 'Completed';
          const combinedDateTime = moment(`${shiftTaskItemInitial?.Date} ${shiftTaskItemInitial?.startingTime}:00`, 'YYYY-MM-DD HH:mm:ss').tz(shiftTaskItem?.timeZone || 'Asia/Calcutta').format();
          const wellbeingObj = {
            _id: shiftTaskItem._id,
            id: shiftTaskItem._id + index + "wellbeingAssessment",
            clientName: shiftTaskItem.forClient,
            clientId: clientId,
            RiskProfile: "",
            TaskType: "/images/s9.svg",
            Task: "Wellbeing",
            notes: "notes",
            shiftPeriod: `${shiftTaskItemInitial?.startingTime} - ${shiftTaskItemInitial?.endingTime}`,
            taskTime: '--:--',
            timeDone: shiftTaskItem?.wellbeingAssessment?.timestamp ? convertToTimeZone(shiftTaskItem?.wellbeingAssessment?.timestamp) : "--:--",
            taskStatus: wellbeingTaskStatus,
            bgColor: wellbeingTaskbgColor,
            shiftId: shiftTaskItem._id,
            date: shiftTaskItemInitial?.Date,
            dateToShort: shiftTaskItem?.wellbeingAssessment?.timestamp ?? combinedDateTime
          }
          if (shiftTaskItem?.wellbeingAssessment?.status === 'true' || shiftTaskItem?.wellbeingAssessment?.status === "omitted") {
            if (shiftTaskItem.wellbeingAssessmentDoneBy === shiftsCareTeamId && shiftTaskItemInitial._id === shiftTaskItem?.wellbeingAssessment?.shiftId) {
              tasksArr.push({ ...wellbeingObj, careteamName: shiftsCareTeamName });
            }
          } else if (shiftTaskItem?.wellbeingAssessment?.status === 'overdue') {
            if (wellOverdueCompDate !== shiftTaskItemInitial?.Date) {
              wellbeingObj.shiftPeriod = '--:--';
              tasksArr.push({ ...wellbeingObj, careteamName: '' });
              wellOverdueCompDate = shiftTaskItemInitial?.Date;
            }
          } else {
            if (wellPendingCompDate !== shiftTaskItemInitial?.Date) {
              if (shiftTaskItem?.wellbeingAssessment?.shiftTiming && shiftTaskItem?.wellbeingAssessment?.status === 'In-Process') {
                wellbeingObj.shiftPeriod = shiftTaskItem?.wellbeingAssessment?.shiftTiming;
              } else {
                wellbeingObj.shiftPeriod = '--:--';
              }
              tasksArr.push({ ...wellbeingObj, careteamName: '-' });
              wellPendingCompDate = shiftTaskItemInitial?.Date;
            }
          }

          // Clock IN Task Passing to Task Array
          if (shiftTaskItem?.checkinDateTime) {
            let checkinbgColor = "green";
            let checkinStatus = "Done";
            checkInOutArr.push({
              _id: shiftTaskItem._id,
              id: shiftTaskItem._id + index + "checkinDateTime",
              clientName: shiftTaskItem.forClient,
              clientId,
              RiskProfile: "",
              TaskType: "/images/s11.svg",
              Task: "Clock-In",
              careteamName: shiftsCareTeamName,
              notes: "notes",
              shiftPeriod: `${shiftTaskItemInitial?.startingTime} - ${shiftTaskItemInitial?.endingTime}`,
              taskTime: shiftStartTime,
              timeDone: convertToTimeZone(shiftTaskItem.checkinDateTime),
              taskStatus: checkinStatus,
              bgColor: checkinbgColor,
              shiftId: shiftTaskItem._id,
              date: shiftTaskItemInitial?.Date,
              dateToShort: combinedDateTime
              // dateToShort: convertToTimeZoneFullTime(shiftTaskItem.checkinDateTime)
            });
          }
          //  Clock Out Task Passing to Task Array 
          if (shiftTaskItem?.checkoutDateTime) {
            let checkoutbgColor = "green";
            let checkoutPlace = shiftTaskItem?.checkoutPlace ? `(${shiftTaskItem?.checkoutPlace})` : '';
            let checkoutStatus = shiftTaskItem.checkoutBy === "careTeamMember" ? "Clock-Out(Manual)" : `Clock-Out(Auto)${checkoutPlace}`;
            checkInOutArr.push({
              _id: shiftTaskItem._id,
              id: shiftTaskItem._id + index + "checkoutDateTime",
              clientName: shiftTaskItem.forClient,
              clientId,
              RiskProfile: "",
              TaskType: "/images/s11.svg",
              Task: checkoutStatus,
              checkoutStatus: checkoutStatus,
              careteamName: shiftsCareTeamName,
              notes: "notes",
              shiftPeriod: `${shiftTaskItemInitial?.startingTime} - ${shiftTaskItemInitial?.endingTime}`,
              taskTime: shiftEndTime,
              timeDone: convertToTimeZone(shiftTaskItem.checkoutDateTime),
              taskStatus: "Completed",
              bgColor: checkoutbgColor,
              shiftId: shiftTaskItem._id,
              date: shiftTaskItemInitial?.Date,
              dateToShort: combinedDateTime
              // dateToShort: convertToTimeZoneFullTime(shiftTaskItem.checkoutDateTime)
            });
          }

          shiftTaskItem.Task?.forEach((task, taskIndex) => {
            let bgColor = "";
            let taskStatus = "";
            let taskTime = "--:--";
            let timeOfTask = '';
            if (task.shiftType?.includes('within')) {
              taskTime = `${convertTimeToUserTimeZone(shiftTaskItemInitial?.startingTime, shiftTimeZone) ?? '--'}`;
              timeOfTask = shiftTaskItemInitial?.startingTime;
            } else {
              taskTime = `${convertTimeToUserTimeZone(task.startTime, shiftTimeZone) ?? '--'}`;
              timeOfTask = shiftTaskItem.startTime;
            }
            const combinedDateTime = moment(`${shiftTaskItemInitial?.Date} ${timeOfTask}:00`, 'YYYY-MM-DD HH:mm:ss').tz(shiftTaskItem?.timeZone || 'Asia/Calcutta').format();
            // let combinedDateTime = combineDateTimeAndTimeZone(shiftTaskItem?.Date, timeOfTask, shiftTaskItem?.timeZone);
            const taskName = taskCtgImages.filter((i) => task.taskName?.includes(i.Ctg));
            let riskprofile = task?.riskProfileCategory ?? "";
            let taskStatusObj = taskStatusColors.find(elem => elem.status === task?.status) ?? taskStatusColors[0];
            bgColor = taskStatusObj?.bgColor;
            taskStatus = taskStatusObj?.name;
            /* *  if task is medication reminder then below status will be in task status
             *  'true' || 'false' || prn || self || assisted || assigned || overdue  || In-Process || omitted*/
            /* if task is except medication reminder then below status will be in task status
               'true' means completed || 'false' means pending || overdue ||  In-Process || omitted */
            if (task.medName) {
              let medImage = "";
              let Task = "";
              if (task?.prn && task?.prn === "Yes") {
                medImage = "/images/s6.svg";
                Task = "PRN Med";
              } else {
                medImage = "/images/s10.svg";
                Task = "Medication Reminder";
              }
              let medTaskObj = {
                _id: task._id,
                id: `${task._id}${index}_${taskIndex}medtask`,
                clientName: shiftTaskItem.forClient,
                clientId,
                RiskProfile: riskprofile,
                TaskType: medImage,
                Task: Task,
                notes: "notes",
                shiftPeriod: `${shiftTaskItemInitial?.startingTime} - ${shiftTaskItemInitial?.endingTime}`,
                taskTime: taskTime,
                timeDone: task?.timestamp ? convertToTimeZone(task?.timestamp) : "--:--",
                taskStatus: taskStatus,
                bgColor: bgColor,
                shiftId: shiftTaskItem._id,
                date: shiftTaskItemInitial?.Date,
                dateToShort: combinedDateTime
              }
              if (task.status === 'true' || task.status === "omitted") {
                if (task.doneBy === shiftsCareTeamId && shiftTaskItemInitial._id === task?.shiftId) {
                  tasksArr.push({ ...medTaskObj, careteamName: shiftsCareTeamName });
                }
              } else {
                if (taskCompareDate !== shiftTaskItemInitial?.Date) {
                  if (task.shiftTiming && task.status === 'In-Process') {
                    medTaskObj.shiftPeriod = task.shiftTiming;
                    medTaskObj.taskTime = task.shiftType?.includes('within') ? task.shiftTiming.split('-')[0] : `${convertTimeToUserTimeZone(task.startTime, shiftTimeZone) ?? '--:--'}`;
                  } else {
                    medTaskObj.shiftPeriod = '--:--';
                    medTaskObj.taskTime = task.shiftType?.includes('within') ? '--:--' : `${convertTimeToUserTimeZone(task.startTime, shiftTimeZone) ?? '--'}`;
                  }
                  tasksArr.push({ ...medTaskObj, careteamName: '-' });
                }
              }
            } else {
              let taskObj = {
                _id: task._id,
                id: `${task._id}${index}_${taskIndex}task`,
                clientName: shiftTaskItem.forClient || "",
                clientId,
                RiskProfile: riskprofile,
                TaskType: taskName[0]?.image || "",
                Task: task.taskType || "",
                notes: "notes",
                shiftPeriod: `${shiftTaskItemInitial?.startingTime} - ${shiftTaskItemInitial?.endingTime}`,
                taskTime: taskTime,
                timeDone: task?.timestamp ? convertToTimeZone(task?.timestamp) : "--:--",
                taskStatus: taskStatus || "",
                bgColor: bgColor,
                shiftId: shiftTaskItem._id,
                date: shiftTaskItemInitial?.Date,
                dateToShort: combinedDateTime
              }
              if (task.status === 'true' || task.status === "omitted") {
                if (task.doneBy === shiftsCareTeamId && shiftTaskItemInitial._id === task?.shiftId) {
                  tasksArr.push({ ...taskObj, careteamName: shiftsCareTeamName || "", });
                }
              } else {
                if (taskCompareDate !== shiftTaskItemInitial?.Date) {
                  if (task.shiftTiming && task.status === 'In-Process') {
                    taskObj.shiftPeriod = task.shiftTiming;
                    taskObj.taskTime = task.shiftType?.includes('within') ? task.shiftTiming.split('-')[0] : `${convertTimeToUserTimeZone(task.startTime, shiftTimeZone) ?? '--:--'}`;
                  } else {
                    taskObj.shiftPeriod = '--:--';
                    taskObj.taskTime = task.shiftType?.includes('within') ? '--:--' : `${convertTimeToUserTimeZone(task.startTime, shiftTimeZone) ?? '--'}`;
                  }
                  tasksArr.push({ ...taskObj, careteamName: '-' });
                }
              }
            }
          });
          taskCompareDate = shiftTaskItemInitial?.Date;
        }
      });
    });

    // const uniqueDataArray = removeDuplicates(tasksArr);
    let combinedArr = [...checkInOutArr, ...tasksArr];

    // let sortedArr = sortByDateDescend(combinedArr, 'dateToShort');
    const sortedArr = combinedArr.sort(compareDates);
    setRowData(sortedArr);
  }
  const compareDates = (a, b) => {
    const dateA = moment(a.dateToShort);
    const dateB = moment(b.dateToShort);

    if (!dateA.isValid() || !dateB.isValid()) {
      // Handle invalid dates
      return 0; // Leave them in the same order
    }

    if (dateA.isBefore(dateB)) {
      return 1; // Swap if dateA is before dateB
    } else if (dateA.isAfter(dateB)) {
      return -1; // Don't swap if dateA is after dateB
    }

    return 0; // Leave them in the same order
  };

  function removeDuplicates(array) {
    const uniqueEntries = [];
    const uniqueMap = new Map();

    for (const entry of array) {
      const key = `${entry.clientId}_${entry.date}_${entry.Task}`;

      if (!uniqueMap.has(key)) {
        uniqueMap.set(key, true);
        uniqueEntries.push(entry);
      }
    }

    return uniqueEntries;
  }

  // -----------  Delete Shift Popup (Show and Hide Handler) Starts ------------ //

  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const handleCloseDeletePopup = () => setIsDeleteOpen(false);
  const handleShowDeletePopup = () => setIsDeleteOpen(true);

  // ---------------------     delete multiple  api    ----------------------------------  //

  async function deletemultiple() {
    const res = await httpAdmin('deleteTask', 'post', { ids: selectedIds }, null);
    if (res.status) {
      handleCloseDeletePopup();
      getShiftsandtaskData();
    }
    ShowSwal(res.status, res.message);
  }

  function selectShiftTasks(ids) {
    const arrayOfIds = rowData.filter((item) => ids.includes(item.id)).map((item) => ({ shiftId: item.shiftId, taskId: item.id }));
    setSelectedIds(arrayOfIds);
  }

  // -----------  Change Shift Status Popup (Show and Hide Handler) Starts ------------ //

  const [isStatusOpen, setIsStatusOpen] = useState(false);
  const handleCloseStatusPopup = () => setIsStatusOpen(false);
  const handleShowStatusPopup = () => setIsStatusOpen(true);

  // --------------------- change the friends and family status (api) ------------------------- //

  async function changeShiftTaskStatus({ taskStatus }) {
    const body = selectedIds?.map((item) => ({
      ...item,
      status: taskStatus.value,
    }));
    const res = await httpAdmin('markTaskStatus', 'post', { ids: body }, null);
    if (res.status) {
      getShiftsandtaskData();
      handleCloseStatusPopup();
    }
    ShowSwal(res.status, res.message);
  }

  function customDateChangeHandler(update) {
    setDateRange(update);
  }

  function handleTaskReload(val) {
    setReloadData(val)
  }

  const handleNavigate = (type) => {
    if (type === 'task-status') setReloadData((prev) => prev + 1);
    history.replace({
      pathname: `${url}/${type}`,
    });
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="container-fluid min_height">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-4">
                  <ul
                    className="nav nav-tabs ract_tab_list border-0 listspaceGap"
                    id="myTab"
                    role="tablist"
                  >
                    <li className="nav-item me-3" role="presentation">
                      <p
                        onClick={() => handleNavigate('schedule')}
                        className={`nav-link curser_point ${window.location.href.includes("schedule") ? "active" : ""
                          }`}
                      >
                        Schedule
                      </p>
                    </li>

                    <li className="nav-item" role="presentation">
                      <p
                        onClick={() => handleNavigate('task-status')}
                        className={`nav-link curser_point ${window.location.href.includes("task-status") ? "active" : ""
                          }`}
                      >
                        Task Status
                      </p>
                    </li>

                  </ul>
                </div>
                <div className="col-md-3 searchForm" style={{ marginLeft: "0px", transform: 'translate(-6%, 0%)' }}>
                  <input
                    type="text"
                    className="form-control"
                    value={searchText}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                    placeholder="search"
                  />
                  <BsSearch className="icon me-2" />
                </div>
                {window.location.href.includes("task-status") &&
                  <div className="col-md-5">
                    <div className="col-md-12 dateFilter_sales">
                      <div className="">
                        <ul className="filter_box">
                          {/* <NavLink
                          exact
                          activeClassName={filterType === "today" ? "active" : ""}
                          to="#"
                          onClick={() => setFilterType("today")}
                        >
                          Today
                        </NavLink> */}
                          <NavLink
                            activeClassName={
                              filterType === "week" ? "active mx-3" : "mx-3"
                            }
                            to="#"
                            onClick={() => setFilterType("week")}
                          >
                            This Week
                          </NavLink>
                          <NavLink
                            activeClassName={
                              filterType === "custom" ? "active" : ""
                            }
                            to="#"
                            onClick={() => setFilterType("custom")}
                          >
                            Custom
                          </NavLink>
                        </ul>
                      </div>
                      {filterType === "custom" ? (
                        <div className="input_space">
                          <DatePicker
                            selectsRange={true}
                            dateFormat="dd/MM/yyyy"
                            startDate={startDate}
                            endDate={endDate}
                            maxDate={new Date()}
                            onChange={(update) => {
                              customDateChangeHandler(update);
                            }}
                            isClearable={true}
                          />
                        </div>
                      ) : (
                        <div className="input_space">&ensp;</div>
                      )}
                    </div>
                  </div>}
              </div>

              <div className="tab-content" id="myTabContent">
                <Switch>
                  <Route path={`${path}/:topicId`}>
                    <RotaRoutes
                      selectShiftTasks={selectShiftTasks}
                      searchText={searchText}
                      handleTaskReload={handleTaskReload}
                      rowData={rowData} />
                  </Route>
                </Switch>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* delete Shift tasks */}
      <Modal
        className="viewModal"
        show={isDeleteOpen}
        onHide={handleCloseDeletePopup}
      >
        <Modal.Header>
          <Modal.Title>
            <span>Delete Task</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <div className="">
                <p>
                  Are you sure you want to delete this{" "}
                  {selectedIds?.length > 1 ? "tasks" : "task"} ?
                </p>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex">
            <button className="btn submitBtn" onClick={handleCloseDeletePopup}>
              No
            </button>
            <button
              className="btn submitBtn"
              onClick={() => {
                deletemultiple();
              }}
            >
              Yes
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* change shift tasks status */}
      <Modal
        className="viewModal"
        show={isStatusOpen}
        onHide={handleCloseStatusPopup}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span>Change Task Status</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 mb-3">
              <label className="form-label">Status</label>
              {/* React Select Along with React Hook Form */}
              <Select
                isClearable
                isSearchable={false}
                className="react-dropdown"
                classNamePrefix="dropdown"
                options={ShiftTaskStatus}
              // onChange={handleStatusChange}
              />
              <p className="error_style"></p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex">
            <button
              type="button"
              className="btn submitBtn"
              onClick={handleCloseStatusPopup}
            >
              Close
            </button>
            <button type="submit" className="btn submitBtn"
              onClick={changeShiftTaskStatus}>
              Update
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Shift;
