import React, { useState } from 'react';
import Chart from 'react-apexcharts'

export default function Donut(props) {

  const [state, setState] = useState({
    options: {
      colors: props.color,
      annotations: {
        position: "front",
      },
      legend: {
        show: false,
        customLegendItems: [
          "skldlfkasldk",
          "sdjhj",
          "sd",
          "sbd",
          "asbdamhms",
          "aaa",
        ],
      },

      dataLabels: {
        enabled: false,
      },

      tooltip: {
        enabled: false,
      },
    },
  });

  return (
    <div className="donut">
      {props.series && <Chart options={state.options} series={props.series} type="donut" width="100%" height="100%" />}
    </div>
  )
}